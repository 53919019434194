import React from 'react'
import { gql } from '@apollo/client'
import { SimpleCtaFragment } from './__generated__/simpleCta'
import { SimpleCta as BaseSimpleCta } from '../../../components/simplecta/SimpleCta'

export type SimpleCtaProps = {
  data: SimpleCtaFragment[]
}

export const SimpleCta: React.VFC<SimpleCtaProps> = ({ data }) => {
  const cta = data[0]

  if (!cta) {
    return null
  }

  return (
    <BaseSimpleCta>
      <BaseSimpleCta section>
        <BaseSimpleCta title>{cta.ctaText}</BaseSimpleCta>
      </BaseSimpleCta>
      <BaseSimpleCta cta href={cta.ctaButtonLink}>{cta.ctaButtonText}</BaseSimpleCta>
    </BaseSimpleCta>
  )
}

export const SIMPLE_CTA_FRAGMENT = gql`
  fragment SimpleCtaFragment on simpleCta_BlockType {
    ctaText
    ctaButtonText
    ctaButtonLink
  }
`