import styled from 'styled-components'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { StyledTitleText } from './styledQuickLinksCard'

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  background: none;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding-left: ${props => props.theme.typography.pxToRem(4)};
  padding-right: ${props => props.theme.typography.pxToRem(4)};

  &:hover {
    ${StyledTitleText} {
      color: ${props => props.theme.palette.secondary.dark};
    }
  }
`

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding-left: ${props => props.theme.typography.pxToRem(4)};
  padding-right: ${props => props.theme.typography.pxToRem(4)};
`
